<script >
export default {
  name: "MarketingDept"
}
</script>

<template>
  <div class="tw-w-full">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<style scoped lang="scss">

</style>